.fp-block,
.fp-sidebar {
  margin: 0 auto 35px;
  position: relative;
  top: 30px;
  clear: both;
  border-collapse: initial;
  background: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.04);
  padding: 25px;
}

.blue-text {
  color: #3333ff;
}

.red-text {
  color: #ff0000;
}

.fp-custom-list {
  max-width: 600px;
  margin: auto auto 15px;
  text-align: left;
  color: #000099;
}

.fp-sidebar {
  text-align: center;
  padding-top: 30px;
  h3 {
    text-align: left;
  }
  a {
    color: #29acff;
  }
}


.footer-page {
  input[type="button"],
  table a {
    background: #29acff;
    border: 1px solid #29acff;
    color: #fff;
    padding: 5px 15px;
    border-radius: 3px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    text-align: center;
    font-size: 16px;
    &:hover {
      background: #f6f8f8;
      color: #000000;
    }
    &.miniengine {
      padding: 0;
      font-size: 14px;
      line-height: 18px;
      width: 20px;
      height: 20px;
      display: inline-block;
      text-align: center;
    }
  }
  .port-links:first-of-type {
    padding-top: 30px;
  }
  select {
    border: 1px solid #dddddd;
    background: #ffffff;
    border-radius: 3px;
    padding: 5px 0;
    &:hover {
      background-color: #29acff;
      border: 1px solid #29acff;
      color: #ffffff;
    }
  }
  & > .fp-block table {
    width: 100%;
    margin-top: 35px;
    & tr > td:last-child {
      width: 95px;
    }
    & tr > td:nth-last-child(2) {
      width: 110px;
    }
  }
}

@media (max-width: 767px) {
  .port-content{
    padding: 10px;
  }

  .fp-block table tr {
    display: table;
    width: 100%;

    td {
      display: table-row;
    }
  }

  .footer-page>.fp-block table tr>td:last-child {
    width: 100%;
  }

  .footer-page>.fp-block table tr:nth-of-type(2)>td {
    display: none;
  }
}
