#page-heading{
  padding: 45px 0 1px;
  background-color: rgb(250, 250, 251);
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.04);

  h1{
    font-size: 18px;
    font-weight: bold;
    @include h-underlined(left, 80px, 15px);
    text-transform: none;
  }

}

@media (max-width: 840px) {
  #page-heading {
    padding-top: 20px;
  }
}
