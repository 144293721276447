.pagination{
  display: block;
  background: #f6f8f8;
  padding-bottom: 32px;

  .pagination-container{
    display: flex;
    gap: 8px;
    margin: auto;
    padding: 0 0.5rem;
    max-width: 1120px;
    color: #f6f8f8;
    text-indent: -999px;
  }

  a, .currentpage{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    padding: 4px 8px;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 2px -1px rgba(31, 31, 31, 0.12), 0 2px 3px -2px rgba(31, 31, 31, 0.08);
    color: #71737A;
    font-size: 12px;
    text-indent: initial;
    text-decoration: none;
  }

  a:not([href]) {
    display: none;
  }

  .currentpage{
    background: #29ACFF;
    color: #fff;
  }
}
