// Hamburger
// ==================================================

$hamburger-padding-x: 10px !default;
$hamburger-padding-y: 10px !default;
$hamburger-layer-width: 40px !default;
$hamburger-layer-height: 2px !default;
$hamburger-layer-spacing: 7px !default;
$hamburger-layer-color: #fff !default;
$hamburger-layer-border-radius: 2px !default;
$hamburger-hover-opacity: 0.7 !default;
$hamburger-hover-transition-duration: 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;

.hamburger {
  padding: $hamburger-padding-y $hamburger-padding-x;
  display: inline-block;
  cursor: pointer;

  transition-property: opacity, filter;
  transition-duration: $hamburger-hover-transition-duration;
  transition-timing-function: $hamburger-hover-transition-timing-function;

  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  outline: none !important;
  z-index: 1051;
}

.hamburger-box {
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  display: inline-block;
  position: relative;
}

.hamburger--spin{

  .hamburger-inner{

    display: block;
    top: 50%;

    transform: rotate(225deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

    &::before,
    &::after {
      content: "";
      display: block;
    }

    &,
    &::before,
    &::after {
      width: $hamburger-layer-width;
      height: $hamburger-layer-height;
      background-color: $hamburger-layer-color;
      border-radius: $hamburger-layer-border-radius;
      position: absolute;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease;
    }

    &::before {
      top: 0;
      transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
      opacity: 0;
    }

    &::after {
      bottom: 0;
      transform: rotate(-90deg);
      transition: bottom 0.1s ease-out,
      transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    }

  }

  &.collapsed{
    .hamburger-inner {

      margin-top: $hamburger-layer-height / -2;
      transform: rotate(0deg);
      transition-duration: 0.22s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

      &::before {
        opacity: 1;
        transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
        top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
      }

      &::after {
        transform: rotate(0deg);
        transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
      }

    }
  }
}
