.support-form-wrap {

  padding: 100px 0 45px;
  background: #0c1239;
  color: #fff;

  h3.underlined{
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 40px;

    @include h-underlined( center, 80px, 40px);
  }

}

.support-form {

  margin: 45px 0;
  font-size: 14px;
  text-transform: uppercase;

  label{
    font-size: 10px;
  }

  .form-control{
    background: #3d4161;
    border: 0;
    color: #fff;
  }

  textarea.form-control {
    height: 192px;
  }

  button{
    margin-top:35px;
    padding: 15px 30px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
  }

}
