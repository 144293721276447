.booking-form-horizontal{
  padding: 48px 0;
  background: #F2F3F5;

  .booking-form-wrapper-horizontal{
    border-radius: .5rem;
  }

  @media (min-width: 991px) {
    .horizontal-booking-form-wrapper{
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }
  }

  @media (max-width: 991px) {
    .horizontal-booking-form-wrapper{
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    .button-submit{
      width: 100%;
    }
  }
}