#main-content {
  img {
    max-width: 100%;
    height: auto;
  }
}

@include media-breakpoint-up(md) {
  .home-template {
    margin: 50px auto;
  }
}

@include media-breakpoint-down(md) {
  .home-template {
    margin-top: 50px;
    margin-bottom: 35px;
  }

  #main-content {
    margin-bottom: 35px;
  }
}

.base-template {
  background: #f6f8f8;
  overflow: hidden;
}

#main-content {
  color: $dark;
  padding-top: 40px;
}

@media (max-width: 840px) {
  #main-content {
    padding-top: 20px;
    margin-bottom: 15px;
  }
}

#blog-content{
  padding: 0 0 32px;
}
