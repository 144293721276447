
.post-with-image {
  @include media-breakpoint-up(xl){
    display: flex;
    gap: 32px;
  }

  .featured-image{
    @include media-breakpoint-up(xl){
      max-width: 320px;
      max-height: 400px;
      img{
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

  }
  @include media-breakpoint-up(xl) {
    > div {
      width: 50%;
    }
  }
}

.last-post, .post {

  .post-with-image {
    display: flex;
    flex-direction: row;
  }

  .post {
    &-details {
      display: flex;
      align-items: center;
      gap: 12px;
      min-height: 30px;
      padding-top: 1.5rem;;
    }

    &-date {
      font-size: 12px;
      line-height: 1rem;
      color: #71737A;
    }

    &-header {
      padding: 1rem 0;
    }

    &-title {
      font-weight: bold;
      font-size: 20px;
      line-height: 1.5rem;
    }
  }
}