.base-form{

  font-size: 12px;
  line-height: 1.2;


  label{
    font-weight: bold;
    text-transform: uppercase;
  }

  .form-control{
    font-size: 12px;
  }

  .btn-group{
    .btn{
      width: 33.3333%;
      padding: 10px;
      font-size: 12px;
      font-weight: normal;
      text-transform: none;
    }
    .btn-light:not(:disabled):not(.disabled).active,
    .show > .btn-light.dropdown-toggle {
      color: $white;
      background-color: #1a7de2;
      border-color: #d3d9df;
    }
    .btn:first-child{
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    .btn:last-child{
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-left: 1px solid #ccc;
    }
    .btn:nth-child(2){
      border-left: 1px solid #ccc;
    }
  }

  .custom-multi-select{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between!important;


  label{
      width: 100%;
    }
    select{
      flex: 0 0 32%;
      max-width: 32%; 
      height: calc(2.0625rem + 2px);
      padding: .375rem .75rem;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      line-height: 1.5;
    }
  }

}

