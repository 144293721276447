.quick-booking-form {

  .info{

    font-size: 14px;
    font-weight: 600;

    .col-6:first-child{

      &:after{
        content: "";
        position: absolute;
        top: 0;
        right: -3px;
        width: 1px;
        height: 100%;
        background: $info;
      }

    }

    img{
      position: relative;
      top: 10px;
      left: 18px;
    }

  }

  form{
    max-width: 400px;
    margin: 15px 0 0;
    padding: 25px;
    background-color: rgba(255, 255, 255, 0.9);
    color: #000;
    box-shadow: 0 2px 35px 0 rgba(0, 0, 0, 0.3);

    label{
      font-size: 12px;
      text-transform: uppercase;
    }

    .form-control{
      font-size: 12px;
      font-weight: 500;
      border: 0;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
    }

    .btn-group{
      .btn{
        width: 33.3333%;
        padding: 10px 6px;
        font-size: 12px;
        font-weight: normal;
        text-transform: none;
      }
      .btn-light:not(:disabled):not(.disabled).active,
      .show > .btn-light.dropdown-toggle {
        color: $white;
        background-color: #29ACFF;
        border-color: #d3d9df;
      }
      .btn:first-child{
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      .btn:last-child{
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-left: 1px solid #fff;
      }
      .btn:nth-child(2){
        border-left: 1px solid #fff;
      }
    }

    button{
      padding: 15px 0;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: bold;
    }

    @include media-breakpoint-down(md){
      padding: 15px;
    }

  }

  .home-form-title{
    display: inline-block;
    max-width: 250px;
    font-size: 12px;
    font-weight: 400;
  }

  .info{
    text-shadow: 1px 1px 5px rgba(0,0,0,0.8);
  }

}

.btn-light,
.form-control{
  color: #000000;
}
