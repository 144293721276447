.card{
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.04);
  border: 0;
  border-radius: 0;
}

.card-body{
  small{
    font-size: 13px;
    font-weight: 500;
  }
}

.card-header{
  text-transform: uppercase;
  font-weight: bold;
  color: $info;
  border-bottom: 0;
}

.card-group{
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.04);
  .card{
    box-shadow: none;
    border-right: 1px solid #eee;
  }
  .card:last-child{
    border-right: 0;
  }
}
