.booking-form{

  font-size: 12px;
  line-height: 1.2;


  label{
    font-weight: bold;
    text-transform: uppercase;
  }

  .form-control{
    font-size: 12px;
  }

  .btn{
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.38);
  }

  .date-section{
    @include media-breakpoint-up(md){
      .form-control{
        width: 110px !important;
      }
    }
  }

  .car_data{
    border-bottom: 1px solid #eee;
    margin-bottom: 15px;
  }

  @include media-breakpoint-up(md){
    .w-240px{
      width: 240px !important;
    }
  }
}
