#backToTopBtn{
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 0;
  background: rgba(#000, 0.5);
  border-radius: 50%;
  &:hover{
    background: #000;
  }

  span{
    display: inline-block;
    color: #fff;
    text-decoration: none;
    font-size: 26px;
    transform: rotate(-90deg);
  }
}