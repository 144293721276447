.booking-form-new {
  padding: 30px 10%;

  .btn-group {
    padding: 0 30%;
    z-index: 0;
  }

  .btn-group .btn-light:not(:disabled):not(.disabled).active,
  .btn-group .show > .btn-light.dropdown-toggle {
    color: #fff;
    background-color: #29acff;
    border-color: #fff;
    font-weight: 600;

    img {
      filter: invert(100%);
    }
  }

  .btn-light {
    font-weight: 600;
    background-color: #fff;
  }

  .btn-light:hover {
    color: #29ACFF;
    border-color: #fff;
    background-color: #fff;
    font-weight: 600;

    img {
      filter: invert(70%) sepia(35%) saturate(6909%) hue-rotate(177deg) brightness(100%) contrast(103%);
    }
  }

  form .btn-group .btn:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  form .btn-group .btn:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left: 1px solid #fff;
  }

  .form-control {
    border-radius: 3px;
    height: 40px;
  }

  select:hover {
    border-color: #29ACFF;
  }

  input.btn.btn-block.btn-danger {
    margin: 29px 0 0;
  }
}

@media (max-width: 1024px) {
  .booking-form-new {
    .btn-group {
      padding: 0;
    }

    .col-2 {
      flex: 0 0 25%;
      max-width: 25%;
    }

    .col-5 {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
}

@media (max-width: 912px) {
  .booking-form-new {
    .btn-group {
      padding: 0;
    }

    .col-3, .col-5 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .col-2 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}

@media (max-width: 540px) {
  .booking-form-new {
    padding: 30px 2%;

    .col-3, .col-5, .col-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .col-2 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}