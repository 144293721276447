.blog-widget {
  padding: 2rem 0;
  border-bottom: 1px solid #E1E1E1;

  &-title {
    padding: 10px 0;
    margin-bottom: 0;
  }

  &-content {
    padding: 16px 0 24px;
  }

  &-view-more {
    font-size: 12px;
    font-weight: 600;
    line-height: 1rem;
    color: #29ACFF;
    text-decoration: underline;

    &:hover {
      color: #29ACFF;
    }
  }
}

.categories-widget {
  &-tags {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }

  &-tag {
    background: #F5F5F5;
    padding: 4px 8px;
    border: 1px solid #E0E0E0;
    border-radius: 2px;
    color: #2A2E3D;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;

    &:hover {
      background: #F7FCFF;
      border-color: #29ACFF;
      color: #2A2E3D;
      text-decoration: none;
    }
  }
}

.authors-widget {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  list-style: none;
  margin: 0;
  padding: 0;

  &-container {
    display: flex;
    gap: 1rem;
  }

  &-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #E0E0E0FF;
    opacity: 1;
    background-color: #F5F5F5FF;
    font-size: 12px;
    line-height: 1rem;
    color: #71737A;
  }

  &-full-name {
    font-size: 1rem;
    line-height: 1.5rem;
    color: #081029;
  }

  &-post-count {
    font-size: 12px;
    line-height: 1rem;
    color: #71737A;
  }
}

.recent-post-widget{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  &-item{}
  &-desc{
    color: #081029;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
  }
  &-date{
    color: #71737A;
    font-size: 12px;
    line-height: 16px;
  }
}

