

body{
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}

#main-content{

    h1, h2, h3, h4{
        text-transform: uppercase;
        font-weight: bold;
        color: $dark;

        span{
            font-weight: normal;
            color: $info;
        }
    }

    h1{
        font-size: 24px;
    }

    h2{
        font-size: 18px;
    }

    h3{

    }

    h1, h2, h3, h4{

        @include h-underlined(left, 80px, 20px);

    }
}
