#homeBookingForm{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 0;

  hr{
    height: 1px;
    background: #E0E1E5;
    margin: 0;
    border: 0;
  }

  label{
    margin: 0;
  }

  .travel-transport-type{
    display: flex;
    gap: 16px;

    .inner-block input{
      display: none;
    }

    label{
      cursor: pointer;
    }

    input:checked ~ label{
      background: #0090FF;
      color: #fff;
      transition: background 0.5s ease;
    }

    input ~ label > .ic_light{
      display: none;
    }
    input:checked ~ label > .ic_dark{
      display: none;
    }
    input:checked ~ label > .ic_light{
      display: inline-flex;
    }
  }

  .travel-type-switcher{
    display: flex;
    gap: 28px;

    label {
      cursor: pointer;
    }
  }

  .travel-destination-wrapper{
    position: relative;
    display: flex;
    box-shadow: 0 2px 3px -2px rgba(8, 16, 41, 0.06), 0px 3px 6px -3px rgba(8, 16, 41, 0.12);

    @media (min-width: 991px) {
      > div:first-child select{
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding-right: 1rem;
        background-position-x: 92%;
      }
      > div:last-child select{
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 0;
      }
    }

    .toggle-destination-button{
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 24px;
      height: 24px;
      margin: auto;
      z-index: 10;
      border: 1px solid #C5C7CD;
      border-radius: 50%;
      background: #fff;
      box-shadow: 0 2px 4px -2px rgba(31, 31, 31, 0.05), 0 3px 6px -3px rgba(31, 31, 31, 0.05);

      img{
        position: absolute;
        top: 0;
        left: -1px;
        transform: rotate(90deg);
        transition: 0.3s linear;

        &.rotate-180{
          transform: rotate(270deg);
          transition: 0.3s linear;
        }
      }
    }
  }

  .form-control-wrapper{
    position: relative;
    width: 100%;

    label{
      position: absolute;
      left: 12px;
      top: 8px;
      color: #676C7A;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }

  .form-control-group{
    display: flex;
    gap: 1rem;
  }

  .form-input{
    display: block;
    width: 100%;
    padding: 28px 12px 8px 12px;
    min-height: 56px;
    color: #373E52;
    border-radius: 4px;
    border: 1px solid #A7AAB2;
    background: #fff;
    &:disabled{
      color: #676c7a;
    }
  }

  .form-select{
    display: block;
    width: 100%;
    padding: 28px 12px 8px 12px;
    border-radius: 4px;
    border: 1px solid #A7AAB2;
    box-shadow: 0 2px 3px -2px rgba(8, 16, 41, 0.06), 0px 3px 6px -3px rgba(8, 16, 41, 0.12);
    background: #fff url("/sites/ferrytravel2/themes/1067962636810/assets/icons/booking/ic_arrow_dropdown.svg") no-repeat 95% center;
    color: #081029;
    font-weight: 500;
    -webkit-appearance: none;
    -moz-appearance: none;

    &:disabled{
      color: #676c7a;
    }
  }

  /* Buttons */

  .button{
    border: 0;
    padding: 14px;
    background: none;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    line-height: 20px;
    min-width: 114px;
  }

  .button-outline{
    display: inline-flex;
    gap: 8px;
    border-radius: 24px;
    border: 1px solid #0091FF;
    background: #fff;
    color: #081029;
    transition: background 0.5s ease;
    &:hover{
      background: #0090FF;
      color: #fff;
      transition: background 0.5s ease;
    }
  }

  .button-submit{
    color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 4px -2px rgba(8, 16, 41, 0.08), 0px 3px 6px -3px rgba(8, 16, 41, 0.12);
    background: #FF0200;
  }

  .autoComplete_wrapper{
    > ul {
      position: absolute;
      max-height: 226px;
      overflow-y: scroll;
      box-sizing: border-box;
      left: 0;
      right: 0;
      margin: .5rem 0 0 0;
      padding: 0;
      z-index: 1;
      list-style: none;
      border-radius: .6rem;
      background-color: #fff;
      border: 1px solid rgba(33, 33, 33, .07);
      box-shadow: 0 3px 6px rgba(149, 157, 165, .15);
      outline: 0;
      transition: opacity .15s ease-in-out;
      -moz-transition: opacity .15s ease-in-out;
      -webkit-transition: opacity .15s ease-in-out;

      scrollbar-width: thin;
      scrollbar-color: #081029 #A7AAB2;


      /* Works on Chrome, Edge, and Safari */
      &::-webkit-scrollbar {
        width: 12px;
      }

      &::-webkit-scrollbar-track {
        background: #081029;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #081029;
        border-radius: 20px;
        border: 3px solid #A7AAB2;
      }

      li {
        margin: .3rem;
        padding: .3rem .5rem;
        text-align: left;
        font-size: 0.75rem;
        color: #373E52;
        border-radius: .35rem;
        background-color: #fff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: all .2s ease;
        cursor: pointer;
      }
    }
  }

  @media (max-width: 991px){
    .travel-destination-wrapper, .form-control-group{
      flex-direction: column;
    }
    .travel-destination-wrapper{
      > div:first-child .form-select{
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 0;
      }
      > div:last-child .form-select{
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
    .travel-type-switcher{
      gap: 8px;
    }
  }
}