

@mixin pseudo($display: block, $pos: absolute, $content: ''){
  content: $content;
  display: $display;
  position: $pos;
}

@mixin h-underlined($alignment: left, $width: 24px, $margin: 20px){

  margin-bottom: 25px;

  &:after{
    content: "";
    position: relative;
    display: block;
    width: $width;
    height: 1px;
    background: $danger;
    @include media-breakpoint-up(md){

      @if $alignment == center {

        margin: $margin auto $margin;

      } @else if $alignment == left {

        margin: $margin 0 $margin;

      }

    }
    @include media-breakpoint-down(md){

      width: 50px;
      margin: 25px auto;

    }

  }

  @include media-breakpoint-down(md){

      font-size: 16px;
      font-weight: bold;
      text-align: center;
      span{
        display: block;
        margin: 10px 0;
        font-weight: bold;
      }
  }

}
