footer{

  background: #192045;
  color: #fff;
  font-size: 14px;

  .wrap{


    @include media-breakpoint-up(md){
      padding-top: 20px;
      padding-bottom: 50px;
    };

    @include media-breakpoint-down(md){
      padding: 50px 30px;
    }

    .logo{
      margin-bottom: 40px;
    }

    .nav-heading{
      padding: 35px 35px 20px;
      margin-bottom: 20px;
      color: #2278b8;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      border-bottom: 1px solid #303758;
    }

    .nav{
      a{
        padding: 15px 35px;
        color:#fff;
        font-size: 12px;
        text-transform: uppercase;
        &:hover{
          color: #2278b8;
          text-decoration: underline;
        }
      }
    }

    @include media-breakpoint-down(md){
      .nav-heading{
        padding: 20px 0;
      }
      .nav{
        a{
          padding: 15px 0;
        }
      }
    }

    .footer-location, .footer-phone{
      list-style: none;
      margin: 0;
      padding: 0;
      li{
        position: relative;

        margin-bottom: 8px;
        padding-left: 35px;

        a{
          color: $white;
        }

        &:before{
          content: "";
          position: absolute;
          left: 0;
          width: 14px;
          height: 19px;
        }
      }
    }

    .footer-phone li{
      &:before{
        background: url("../icons/phone-blue.png") no-repeat;
      }
    }

    .footer-location{
      margin-bottom: 40px;

      li{
        &:before{
          background: url("../icons/marker-blue.png") no-repeat;
        }
      }
    }

  }

  .copyright{
    padding: 25px 0;
    background: #1f2753;
    font-size: 10px;
  }

  .footer-right{
    font-size: 14px;
  }

}
