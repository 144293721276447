.blog-list-wrap{
  h4{
    margin: 20px 30px;
    font-size: 14px;
    font-weight: bold;
    color: $dark;
  }
}

.blog-list{
    padding: 45px 30px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.05);
    border-top: 4px solid $dark;
    .post-date{
      //color: #898989;
      color: $info;
      margin-bottom: 8px;
    }
    .post-excerpt{
      line-height: 2;
    }
}
