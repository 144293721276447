#top-nav{

  background: #0a1336;
  color: #fff;
  font-size: 11px;
  text-align: right;


  .phone{
    padding: 15px;
    img{
      margin-right: 20px;
    }
  }

  a {
    font-size: 15px;
  }

  .email{
    padding: 15px 15px 15px 20px;
    img{
      margin-right: 15px;
      margin-top: -3px;
      filter: brightness(0) invert(1);
    }
  }

  @include media-breakpoint-down(md){

    .phone, .email{
      padding: 15px 10px;
      img{
        margin-right: 5px;
      }
    }
  };

  @include media-breakpoint-down(sm){
    .phone, .email{
      font-size: 12px;
    }
    .phone img{
      width: 16px;
      height: 17px;
    }

    a {
      font-size: 12px;
    }
  }

}