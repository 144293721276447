table.doc-table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: rgba(0, 0, 0, 0);
  border-collapse: collapse;
  border-spacing: 2px;
  border-color: grey;
  font-weight: normal;
}

table.doc-table tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}

table.doc-table tr:first-child td.doc-table-cell {
  vertical-align: center;
  text-align: center;
  background-color: #0d1b46 !important;
  font-weight: bold;
  padding: 0.75rem;
  color: #f8f9fa !important;
  border-top: 1px solid #dee2e6;
  border-bottom: 2px solid #dee2e6;
}

td.doc-table-cell {
  color: #000;
  padding: 5px 5px 5px 25px;
  width: auto;
  text-align: center;
}