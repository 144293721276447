.breadcrumb-wrapper {
  padding: 1.5rem 0;
    .breadcrumb-item.active {
      @include media-breakpoint-down(md) {
        width: 160px;
      }
      @include media-breakpoint-up(md) {
        width: 460px;
      }
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
}

.blog-content {
  padding: 64px 64px 72px 64px;
  background: #fff;
  @include media-breakpoint-down(md) {
    padding: 1rem;
  }
}

.blog {
  &-container {
    @include media-breakpoint-up(lg) {
      display: flex;
      gap: 64px;
    }
  }

  &-content {
    margin-bottom: 2rem;

    h1, h2, h3, h4 {
      font-weight: bold;
      color: #081029;
    }

    h5, h6 {
      font-weight: normal;
      color: #2A2E3D;
    }

    h1 {
      font-size: 40px;
      line-height: 48px;
    }

    h2 {
      font-size: 28px;
      line-height: 32px;
    }

    h3 {
      font-size: 20px;
      line-height: 24px;
    }

    h4, h5 {
      font-size: 16px;
      line-height: 24px;
    }

    h6 {
      font-size: 14px;
      line-height: 20px;
    }

    @include media-breakpoint-down(md) {
      h1 {
        font-size: 20px;
        line-height: 28px;
      }
      h2 {
        font-size: 18px;
        line-height: 22px;
      }
      h3 {
        font-size: 16px;
        line-height: 20px;
      }
      h4, h5 {
        font-size: 14px;
        line-height: 20px;
      }
    }

  }

  &-post-list {
    flex: 1;
    max-width: 672px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    @include media-breakpoint-down(md) {
      padding-bottom: 60px;
    }
  }

  &-sidebar {
    @include media-breakpoint-up(md) {
      width: 256px;
    }

    .blog-widget{
      &:first-child{
        padding-top: 0;
      }
      &:last-child{
        padding-bottom: 0;
        border-bottom: 0;
      }
    }
  }

  article {
    position: relative;
    border-bottom: 1px solid #E1E1E1;
    padding-bottom: 32px;

    @include media-breakpoint-down(md) {
      padding-bottom: 25px;
    }
  }

  article:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .post {
    &-btn {
      display: inline-flex;
      align-items: center;
      padding: 13px 16px;
      color: #29ACFF;
      border-radius: 4px;
      border: 1px solid #29ACFF;
      box-shadow: 0 1px 2px -1px rgba(31, 31, 31, 0.12), 0 2px 3px -2px rgba(31, 31, 31, 0.08);
      background: #F7FCFF;
      text-decoration: none;
      font-size: 12px;
      font-weight: bold;
      line-height: 1;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      transition: all 0.3s;

      &:hover {
        color: #fff;
        background: #29ACFF;
      }
    }

    &-content {
      color: #2A2E3D;
      font-size: 1rem;
      font-weight: normal;
      line-height: 1.5rem;
      @include media-breakpoint-down(md) {
        font-size: 0.9rem;
      }

      h1, h2, h3, h4, h5, h6 {
        margin-bottom: 1rem;
      }
    }
  }
}