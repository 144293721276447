#main-nav{

  @include media-breakpoint-up(lg){
    padding: 0;

    #navbarNav{
      justify-content: start;

    }

    .nav-item.dropdown{

      .dropdown-toggle::after{
        width: 7px;
        height: 4px;
        margin-left: 5px;
        background: url('../icons/dropdown-arrow-blue.svg') no-repeat;
        border:0;
      }

        .dropdown-menu{
          display: block;
          visibility: hidden;
          opacity: 0;
          transform: translateY(1em);
          transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
        }

      &:hover{

        .dropdown-menu{
          position: absolute;
          top: 75px;
          left: 0;
          padding: 0;

          visibility: visible; /* shows sub-menu */
          opacity: 1;
          z-index: 1;
          transform: translateY(0%);
          transition-delay: 0s, 0s, 0.3s;



          .dropdown-item{
            font-size: 12px;
            padding: 18px 20px;
            border-bottom: 1px solid #e5e5e5;
            &:hover{
              color: $white;
              background: #213984;
            }
          }
          .dropdown-item:first-child{
            &:before{
              content: "";
              position: absolute;
              top: -5px;
              left: 15px;
              width: 10px;
              height: 10px;
              background: #fff;
              transform: rotate(-45deg);
            }
            &:hover{
              &:before{
                background: #213984;
              }
            }
          }
          .dropdown-item:last-child{
            border-bottom: 0;
          }
        }

      }
    }

    .nav-link{
      padding: 50px 13px;
      font-size: 12px;
      font-weight: bold;
      color: #fff;
      text-transform: uppercase;
    }

  };

  @include media-breakpoint-down(md){
    padding: 1rem 1.5rem;
    z-index: 1050;

    .navbar-collapse {
      position: fixed;
      top: 51px;
      right: 1%;
      padding: 80px 15px 20px;
      width: 100%;
      height: 100%;
      transition: all 0.3s ease-in;
      background: $primary;
      z-index: 1050;
      .navbar-nav{
        overflow-y: scroll;
        height: calc(100% - 51px);
      }
    }
    .navbar-collapse.collapsing {
      transition: all 0.2s ease;
      right: 100%;
      height: auto;
    }
    .navbar-collapse.show {
      right: 0;
      height: 100%;
    }

    .nav-link{
      padding: 10px 15px 5px;
      color: #fff;
      font-size: 14px;
      text-transform: uppercase;
    }

    .dropdown-toggle::after{
      width: 7px;
      height: 4px;
      margin-left: 5px;
      background: url('../icons/dropdown-arrow-blue.svg') no-repeat;
      border:0;
    }

    .navbar-nav .dropdown-menu {
      position: relative;
      display: block;
      background: transparent;
      border: 0;

      .dropdown-item{
        color: $info;
        &:hover{
          background: transparent;
          color: $info;
        }
      }
    }

  };


}
