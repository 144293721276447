.home-intro-title{
  max-width: 240px;
  color: #fff;
  margin-bottom: 25px;
}

.home-intro-wrapper{
  display: flex;
  border-radius: .5rem;

  .home-intro-caption{
    flex: 1;
    background: #1A9CFF url('/sites/ferrytravel2/themes/1067962636810/assets/icons/booking/ic_ship.svg') no-repeat 95% 80%;
    color: #fff;
    padding: 1.5rem 2.5rem 1.5rem 1.5rem;
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;

    p{
      max-width: 290px;
    }

    .ferry-phone-number{
      display: block;
      font-size: 20px;
      font-weight: 700;
      letter-spacing: -0.5px;
      line-height: 1.5rem;
      text-decoration: none;
      color: #fff;
      margin-bottom: 1rem;
    }
    .ferry-slogan{
      font-size: 28px;
      font-weight: 700;
      letter-spacing: -0.7px;
      line-height: 2rem;
      margin-bottom: 1.5rem;
    }
  }
}

.booking-form-wrapper, .booking-form-wrapper-horizontal{
  flex: 1;
  background: #fff;
  padding: 1.5rem;
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;

  box-shadow: 0 24px 48px -24px rgba(31, 31, 31, 0.12), 0px 48px 96px -48px rgba(31, 31, 31, 0.08);
}

@media (max-width: 991px){
  .home-intro-caption{
    display: none;
  }
  .booking-form-wrapper{
    border-radius: .5rem;
  }
}