.blog-author{
  &-header{
    padding: 8px 0 24px;
  }
  &-title{
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 8px;
  }
  &-sub-title{
    color: #71737A;
    font-size: 12px;
    line-height: 16px;
  }
  &-bio{
    font-weight: 400;
    padding: 0 0 16px;
  }
  &-details{
    padding: 0 0 16px;
  }
  &-details-title{
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
    color: #2A2E3D;
  }
  &-post-count{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    list-style: none;
    margin: 0;
    padding: 0;
    color: #71737A;
    font-size: 12px;
  }
}