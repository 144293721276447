form[name='form2'] {
  display: none;
}

.us-ports, .cdn-ports {
  margin: 0 auto 70px;
  position: relative;
  top: 30px;
  clear: both;
  border-collapse: initial;
  background: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.04);

  thead {
    td {
      text-transform: uppercase;
      font-weight: bold;
      color: #29acff;
      font-size: 14px;
      padding: 20px 25px 10px;

      &:first-child {
        padding-left: 35px;
      }

      &:last-child {
        padding-right: 35px;
      }
    }
  }

  tbody {
    tr:last-child td a {
      border-bottom: none;
      padding-bottom: 35px;
    }

    td {
      padding: 0 10px;

      a {
        width: 240px;
        padding: 10px 25px;
        display: block;
        font-size: 12px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);

        &:hover {
          color: #990000;
          text-decoration: underline;
        }
      }

      &:first-child {
        padding-left: 20px;
      }

      &:last-child {
        padding-right: 20px;
      }
    }
  }
}

.port-links {
  strong {
    display: block;
  }

  a, span {
    border: 1px solid #dddddd;
    background: #ffffff;
    border-radius: 3px;
    padding: 5px;
    display: inline-block;
    margin: 5px 5px 5px 0;

    &:hover {
      background-color: #428bca;
      border: 1px solid #428bca;
      color: #ffffff;
      text-decoration: none;
    }
  }

  span, span:hover {
    background-color: transparent;
    color: #0d1b46;
    border: 1px solid #dddddd;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
  }
}

.port-content {
  background: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.04);
  padding: 40px;
  margin-bottom: 40px;

  .port-content-block:last-of-type {
    border-bottom: none;
  }
}

.single-content a {
  color: #29acff;
  display: inline-block;
}

.port-content-block {
  border-bottom: 1px solid #cbcbcb;
  padding-bottom: 30px;
  padding-top: 20px;

  ul {
    list-style-type: none;
    padding-left: 0;
  }
}

.port-title {
  margin-bottom: 65px;
  padding: 45px 0 1px;
}

#main-content .ports-img-text {
  display: inline-block;
  text-align: justify;

  img {
    max-width: 50%;
    margin-right: 15px;
    margin-bottom: 15px;
    float: left;
  }

  a {
    color: #29acff;
    display: inline-block;
  }
}

.rs-title {
  text-align: center;

  h3 {
    margin-top: 20px;
    font-size: 18px;
    text-align: left;
  }
}

#main-content .rs-title h3 {
  margin-top: 0;
}

.rs-subtitle {
  color: #29acff;
}

.reserv-sidebar {
  .card-header {
    background-color: #1a7de2;
    color: #ffffff;
  }

  .card {
    box-shadow: none;
  }

  .card-body {
    background: #f6f8f8;
  }

  .base-form .btn-group .btn {
    background: #ffffff;
  }
}

.reservation-contacts {
  text-align: center;
}

.rs-table {
  margin-top: 40px;
  background: #f6f8f8;

  thead tr {
    background: #1a7de2;
    font-weight: bold;

    td {
      border-right: 1px solid #ffffff;
      color: #ffffff;
      vertical-align: middle;

      &:nth-last-child {
        border-right: none;
      }

      a {
        color: #ffffff;
        text-decoration: underline;
      }
    }
  }

  td {
    padding: 10px;
    vertical-align: top;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    font-size: 18px;
    margin-bottom: 5px;
  }

  a {
    color: #1a7de2;
  }
}

.rs-under-table {
  text-align: center;
  margin-top: 30px;

  a {
    color: #29acff;
  }
}

// temp
.img-text-block {
  .itb-img {
    margin-right: 20px;
    margin-bottom: 20px;
    float: left;
    width: calc(40% - 20px);
  }

  .itb-text-title,
  .itb-text-content {
    float: left;
    max-width: 60%;
  }
}

.port-inside-table {
  max-width: 700px;
  margin: 45px auto 30px;

  table {
    width: 100%;

    td, th {
      padding: 15px;
      border: 1px solid #000000;
    }

    th {
      background: #bfbfbf;
    }

    tr td:nth-of-type(1) {
      color: #2f1ac0;

      span {
        color: #000000;
      }
    }

    .port-inside-table-text-note {
      font-style: italic;

      .red-char {
        color: red;
      }
    }
  }
}

@media (max-width: 1024px) {

  .port-content.port-content-mobile-fix {
    .base-form {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      max-width: 100%;
      padding: 0 15px;
    }

    .col-md-6 {
      flex: initial;
      max-width: initial;
    }
  }

}
