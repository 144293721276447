header {

}

#intro {

  @include media-breakpoint-up(lg){
    background: url("../img/home-banner.jpg") no-repeat top;
    background-size: cover;
    padding: 50px;
    min-height: calc( 100vh - 169px);
  };

  @include media-breakpoint-down(md){
    background: url("../img/home-banner-mob.jpg") no-repeat top;
    background-size: contain;
    padding-top: 190px;
  };

  .intro-caption{

    color: $white;
    font-size: 16px;
    font-weight: bold;
    text-shadow: 1px 1px 5px rgba(0,0,0,0.8);

    h1 {
      font-size: 40px;
      font-weight: 400;
      color: $white;
      margin: 10px 0 10px;
      @include h-underlined(left, 80px, 20px);
      text-transform: none;
    }

    p {
      font-size: 17px;
      font-weight: 400;
    }

    .btn {
      padding: 15px 30px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: bold;
    }

    @include media-breakpoint-down(md){
      display: none;
    }
  }

  .intro-caption-container {
    background: rgb(41, 172, 255, 0.6);
    padding: 10px 100px 5px 40px;
    margin: 66px 0 0;
  }

  .intro-caption-container::before {
    content: "";
    position: relative;
    display: block;
    width: 80px;
    top: -10px;
    height: 4px;
    background: red;
  }
}

#intro .intro-caption h1::after {
  width: 0;
}

#advantages {
  padding: 21px 0;
  background: #f5f6fa;
  font-size: 12px;
  line-height: 2;
  box-shadow: 0 4px 29px 0 rgba(0, 0, 0, 0.14);

  a {
    color: #000000;
  }

  .col-md-4{

      &:after {
        content: "";
        position: absolute;
        @include media-breakpoint-up(md){
          top: 0;
          width: 1px;
          height: 71px;
        }
        @include media-breakpoint-down(md){
          width: 80%;
          height: 1px;
          bottom: 0;
          left: 0;
          margin: auto;
        }
        right: 0;
        background: #c6c7c8;
      }
  }

  .col-md-4:last-child{

    &:after{
      display: none;
    }

  }

  @include media-breakpoint-down(md){
    text-align: center;

    img{
      margin: 0 0 10px 0;
    }
  };

}

@media (max-width: 991px){

  #intro{
    background-size: cover;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .dropdown-menu {
    top: 0;
  }

  #main-nav .nav-item.dropdown .dropdown-menu {
    opacity: 1;
    visibility: visible;
    transition: none;
    transform: none;
  }

  #main-nav .navbar-nav .dropdown-menu .dropdown-item {
    color: #ffffff;
  }

}
