
.single-blog-page{
  .featured-image{
    margin-bottom: 32px;
  }
  .post{
    &-title{
      margin-bottom: 32px;
      text-transform: none;
      &:after{
        display: none;
      }
    }
    &-details{

      margin-bottom: 1rem;
      address{
        color: #081029;
      }
      @include media-breakpoint-up(md) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    &-date{
      font-size: 12px;
      line-height: 1rem;
      color: #71737A;
    }
    &-date-cat{
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }
}